@import '../../../assets/styles/variables.scss';

.wrapper {
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 2%;
  background-color: var(--black100);
  padding: 0.5rem;
  transform: translate(-1rem, -1rem);
  border-radius: $b-radius;
  opacity: 20%;
  transition: $transition;
  gap: 1rem;

  @include flex(center, row, center);

  &:hover {
    opacity: 100%;
  }

  .icon {
    @include size(20px);

    &.smallSize {
      @include size(15px);
    }

    color: var(--whity);
    cursor: pointer;
    transition: $transition;

    &:hover {
      color: var(--primary);
    }

    &.error {
      color: var(--danger);

      &:hover {
        color: var(--danger);
      }
    }

    &.disabled {
      color: var(--disabled);
      cursor: not-allowed;

      &:hover {
        color: var(--disabled);
      }
    }
  }
}
