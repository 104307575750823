@import '/src/assets/styles/variables.scss';

.container {
  @include flex-gap(2rem);

  background-color: var(--bg);
  padding: $padding;
  border-radius: $b-radius;
  box-shadow: $box-shadow;

  .title {
    margin: 0;
    font-weight: bold;
  }

  & > ul {
    @include flex-gap(1rem);

    background-color: var(--bg) !important;
    padding: 0 !important;
    box-shadow: none !important;
    border-radius: 4px !important;

    & > li {
      width: 100%;
      background-color: var(--bg-tint);
      text-align: left;
    }
  }
}
