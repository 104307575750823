@import '/src/assets/styles/variables.scss';

.block {
  @include flex-gap(2rem, flex-start, column, flex-start);
  @include size(100%);

  overflow-y: hidden;

  &.center {
    @include flex-gap(2rem);
  }
}
