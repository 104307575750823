@import '../../../../../assets/styles/variables.scss';

.container {
  @include flex;

  & > p {
    margin: 0;
  }

  .disabledText {
    color: var(--danger);
  }

  .btn {
    padding: $padding;
    font-size: 12px;
    font-weight: bold;
    border-radius: $b-radius;
  }
}
