@import '../../../../assets/styles/variables.scss';
@import '../../../../assets/styles/zIndex.scss';

$btn-size: 28px;
$top-offset: 8px;

@mixin media-width-max($width-max, $font-size, $width-svg) {
  @media (max-width: $width-max) {
    font-size: $font-size;

    span {
      @include flex;
      @include size($width-svg);
    }
  }
}

.header {
  @include flex-gap(1rem, flex-start, column, flex-start);

  position: relative;
  min-height: 3rem;
  width: 100%;
  margin-bottom: 10px;

  .headerTitle {
    @include flex-gap(1rem, center, row);

    width: 100%;
    font-size: 1.2em;

    h1 {
      color: var(--font-color);
      margin-bottom: 0;
    }

    @include media-width-max(($media-xl + 100), 1em, 40px);
    @include media-width-max(($media-l + 310), 0.9em, 35px);
    @include media-width-max(($media-l + 220), 0.8em, 30px);
    @include media-width-max(($media-l + 120), 0.6em, 20px);
    @include media-width-max($media-l, 0.5em, 20px);

    @media (max-width: ($media-l + 220)) {
      margin-top: 0.5rem;
    }
  }

  .headerTitleRightPanel {
    @include flex(flex-start, row);

    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 0.8em;

    h1 {
      padding: 1rem;
      margin: 0;
      font-size: 16px;
      font-weight: bolder;
    }
  }

  .subtitle {
    @include flex-gap(1rem, center, row);

    width: 100%;
    font-size: 18px;

    @include media-width-max(($media-xl), 16px, 30px);
    @include media-width-max(($media-l), 14px, 35px);

    & > h4,
    p {
      margin: 0;
      font-weight: bold;
    }

    & > span {
      @include size(20px);
    }

    &.default {
      @include size(100%, 30px);
    }
  }
}
