@import '/src/assets/styles/zIndex.scss';
@import '/src/assets/styles/mixins/antdMixins.scss';
@import '/src/assets/styles/mixins/basicMixins.scss';

$transition-time: 0.5s;
$transition-time-tact: 0.2s;
$transition: $transition-time ease-in-out;
$transition-tact: $transition-time-tact ease-in;
$sidebar-width: 80px;
$minific-map-width: 300px;
$panel-width: 312px;
$info-panel-width: 500px;
$notification-panel-width: 500px;
$notification-panel-right: 110px;
$b-radius: 6px;
$b-radius-antd-table: 8px;
$detailed-height: 82vh;
$input-height: 40px;
$input-padding: 5px;
$widget-padding: 1rem 1.5rem;
$simple-accordion-width: 245px;
$box-shadow: 1px 1px 20px #0003;
$box-shadow-lite: 1px 1px 10px #afadb352;
$box-shadow-primary: 1px 1px 20px var(--antd-blue);
$inputs-filter: invert(0%) sepia(1%) saturate(21%) hue-rotate(314deg)
  brightness(119%) contrast(74%);
$svg-icons-filter: invert(0%) sepia(1%) saturate(21%) hue-rotate(314deg)
  brightness(350%) contrast(74%);
$table-row-hover-filter: invert(15%) sepia(3%) saturate(90%) hue-rotate(190deg)
  brightness(99%) contrast(99%);
$map-btns-right: 50px;
$max-confirm-width: 300px;

/* media-sizes */
$media-xxl: 1800px;
$media-xl: 1600px;
$media-l: 1280px;
$media-m: 1024px;
$media-sm: 900px;
$media-s: 800px;
$media-xs: 640px;

/* indents */
$padding: 1rem;
$margin: 1rem;
$padding-card: 24px;

/* borders */
$b-radius-bottom: 0 0 $b-radius $b-radius;
$b-radius-top: $b-radius $b-radius 0 0;
$b-radius-left: $b-radius 0 0 $b-radius;
$b-radius-right: 0 $b-radius $b-radius 0;

%space-between {
  @include flex(space-between, row);

  width: 100%;
}

%absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%empty {
  height: 50% !important;
  width: 50% !important;
  font-size: 20px !important;

  &:first-child {
    @include flex;

    height: 100% !important;
    width: 100% !important;

    div[class*='image'] {
      @include size(30%);
    }
  }

  svg {
    @include size(100%);
  }
}

%tabs-full {
  @include size(100%);

  & > div {
    width: 100%;
  }

  div[class*='content-holder'] {
    @include size(100%);

    & > div {
      @include size(100%);

      & > div {
        @include size(100%);
      }
    }
  }

  div[role='tablist']::before {
    border-bottom: 1px solid var(--bg-tint) !important;
  }
}
