@import '/src/assets/styles/variables.scss';

.titleContainer {
  @include flex-gap(1rem, flex-start, column, flex-start);

  width: 250px;

  .title {
    margin: 0;
    font-weight: bold;
  }

  .description {
    span[class*='label'] {
      font-size: 12px;
    }

    span[class*='content'] {
      font-size: 11px;
    }
  }
}
