@import '../../../../assets/styles/variables.scss';

$opacity: 80%;
$opacity-disabled: $opacity - 5%;

@mixin opacity($h-opacity: $opacity, $opacity: undefined) {
  opacity: $opacity;

  &:hover {
    opacity: $h-opacity;
  }
}

@mixin color($color, $bg-color) {
  color: $color !important;
  background-color: $bg-color !important;
}

%transition {
  transition: $transition;

  &:hover {
    transition: $transition;
  }
}

@mixin btn($color, $hover-color: undefined, $bg-color: undefined, $opacity: $opacity) {
  @include opacity($opacity);
  @include color($color, $bg-color);
  @extend %transition;

  border: none !important;
}

%wrappers {
  position: absolute;
  left: 50%;
  padding: 0.5rem;
  font-size: 1.2rem;
  transform: translateX(-50%);
  border-radius: $b-radius;
}

.phaseCircleContainer {
  position: relative;
}

.trafficLightId {
  @extend %wrappers;
  @include flex(center, row);

  bottom: -13px;
  height: 32px;
  padding: 0;
  overflow: hidden;
  pointer-events: all;
}

.trafficLightIdOffset {
  bottom: -27px;
}

.detailedBtn {
  @include btn(var(--font-color));

  padding: 4px 8px !important;
}

.disabledBtn {
  @include color(var(--font-color-dark),var(--disabled-tint-dark));

  border-color: var(--disabled-tint-dark) !important;
  border-radius: $b-radius !important;
}

.coordinated {
  @include btn(var(--white), var(--whity), var(--green200));
}

.coordinatedDisabled {
  @include opacity($opacity-disabled, $opacity-disabled);
}

.whity {
  @extend %transition;

  &:hover {
    background-color: var(--whity) !important;
  }
}

.isActive {
  @include btn(var(--white), var(--whity), var(--primary));
}
