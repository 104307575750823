@import '../../../assets/styles/variables.scss';

.main {
  position: relative;
  width: 100%;
  min-width: 100px;
  font-size: 12px;
  border-radius: $b-radius;
  border: 1px solid var(--disabled-text);
  overflow-y: hidden;

  .editBlock {
    position: absolute;
    z-index: 2;
    top: 5px;
    right: 10px;
    display: flex;
    width: min-content;

    .editBtn {
      color: var(--font-color);
      padding: 4px;
      margin-left: 4px;
      font-size: 10px;
      border: 1px solid var(--font-color-lite);
      opacity: 40%;
      border-radius: 4px;
      transition: $transition;
      cursor: pointer;

      @include flex(center, row, center);

      &:hover {
        opacity: 100%;
      }
    }
  }

  .code {
    display: block;
    max-height: 700px;
    min-height: 80px;
    padding: 10px;
    overflow: auto;

    .textArea {
      position: relative;
      display: block;
      max-height: 100%;
      width: 100%;
      max-width: 89%;
      background: none;
      font-family:
        SFMono-Regular,
        Consolas,
        'Liberation Mono',
        Menlo,
        Courier,
        monospace;
      font-size: 1em;
      resize: none;
      overflow: hidden;
      border: none;
      outline: none;
    }
  }
}

.redBorder {
  border: 1px solid red;
}

.quote {
  color: var(--font-color-lite);
}
