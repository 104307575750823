@import '../../../../../assets/styles/variables.scss';

.wrapper {
  @include flex-gap(1rem, center, column, center);

  width: 100%;

  .imgWrapper {
    @include flex(center);

    .img {
      @include size(100%);
    }
  }
}
