$margin-size: 6px;

.mapDistrictsButtonsContainer {
  display: flex;
  margin-top: 0;
  margin-bottom: 2 * $margin-size;
}

.mapDistrictsButton {
  margin-right: $margin-size;
}
