@import '../../../../assets/styles/variables.scss';

.buttonsBlock {
  @include flex-gap(1rem, flex-start, row);

  position: fixed;
  bottom: 0;
  flex-wrap: wrap;
  height: 60px;
  width: 100%;
  background: var(--bg);

  .phaseButton {
    @include flex(space-between, row);

    padding: 6px 8px;
    margin-left: -15px;
    border-radius: $b-radius;

    p {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }

  .detailsBtn {
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: $b-radius;
    text-transform: uppercase;

    :disabled {
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }
}
