@import '/src/assets/styles/variables.scss';

$border-radius: 6px;
$height: 40px;
$margin-size: 8px;
$main-font-size: 14px;
$child-font-size: 12px;
$icon-font-size: 16px;
$title-block-width: 260px;
$title-line-height: 18px;

.statisticBlockTitle {
  @include flex(space-between, row);

  height: $height;
  width: 100%;
  padding: 0;
  margin-bottom: $margin-size;
  border-radius: $b-radius;
  border: 1px solid var(--bg-tint);
  border-right: 3px solid var(--green);
  cursor: pointer;
}

.statisticBlock {
  display: flex;
  align-items: center;
  max-width: $title-block-width;
}

.statisticTitle {
  line-height: $title-line-height;

  &:hover {
    cursor: pointer;
  }
}

.statisticChevron {
  width: 28px;
  background: var(--bg);
  padding: 0;
  margin-top: 5px;
  font-size: $icon-font-size;
  font-weight: 300;
  cursor: inherit;
}

.statisticValue {
  color: var(--warning);
  margin-right: $margin-size;
  font-size: $main-font-size;
  font-weight: bold;
}

.childContainer {
  padding: $margin-size;
  margin: - $margin-size calc($margin-size / 2) $margin-size;
  border: 1px solid var(--bg-tint);
}

.childRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $child-font-size;
}

.childRowTitle {
  font-size: $child-font-size;
}

.childRowValue {
  color: var(--warning);
  font-size: $child-font-size;
}
